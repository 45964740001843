//IA DOCUMENTAIRE VAR
html {
  --primary-color: #009ee0;
  --secondary-color: #3c4e57;
}

//variables layout
//mk-layout
html {
  --backgroundLayout: #fff;
  --backgroundContentLayout: #f8f8f8;
  --boxShadowLayout: 0px 0px 12px 4px rgba(0, 0, 0, 0.06);
  --borderRadiusLayout: 18px;
}

//variables button & theme
//mk-button
html {
  //default
  button {
    --btnBackground: var(--primary-color);
    --btnColor: #ffffff;
    --btnBorderRadius: 8px;
    --btnBoxShadow: unset;

    //label text
    --btnLetterSpacing: .3px;
    --btnFontWeight: 600;
    --btnFontSize: 14px;
    --btnFontFamily: 'Montserrat', sans-serif;
  }

  button.googleType {
    --btnBackground: #ffffff;
    --btnColor: var(--secondary-color);
    --btnBoxShadow: 0 0 4px 4px rgba(0,0,0,.08);

    &:hover{
      --btnBackground: #ffffff;
      --btnBoxShadow: 0 0 4px 4px rgba(0,0,0,.15);
    }
  }

  button.microsoftType {
    --btnBackground: #ffffff;
    --btnColor: var(--secondary-color);
    --btnBoxShadow: 0 0 4px 4px rgba(0,0,0,.08);

    &:hover{
      --btnBackground: #ffffff;
      --btnBoxShadow: 0 0 4px 4px rgba(0,0,0,.15);
    }
  }

  button.greyType {
    --btnBackground: #e6e6e6;
    --btnColor: var(--secondary-color);
  }

  button.whiteType {
    --btnBackground: #fff;
    --btnColor: var(--secondary-color);
  }

  button.successType {
    --btnBackground: #279507;
    --btnColor: #fff;
  }

  button.warningType {
    --btnBackground: #e29828;
    --btnColor: #fff;
  }

  button.dangerType {
    --btnBackground: #E22525;
    --btnColor: #fff;
  }

  button.whiteBorderType {
    --btnBackground: transparent;
    --btnColor: #fff;
    --btnBoxShadow: 0 0 0 1px rgba(255,255,255,1);
    padding: 15px!important;
  }

  button.blueBorderType {
    --btnBackground: transparent;
    --btnColor: var(--primary-color);
    --btnBoxShadow: 0 0 0 1px var(--primary-color);
    padding: 15px!important;
  }
}

//variables link
html {
  //link a
  --linkColor: #1e60da;
  --linklightedColor: #103a87;
}

//variables form field & input
//mk-form-field
html {
  //label
  --formFieldLabelColor: #282828;
  --formFieldLabelFontWeight: 600;
  --formFieldLabelFontSize: 12px;
  --formFieldLabelLineHeight: 14px;
  --formFieldLabelTextTransform: none;
  --formFieldLabelLetterSpacing: .7px;

  //input
  --formFieldColorBorder: var(--primary-color);
  --formFieldHighlightedColor: var(--secondary-color);
  --formFieldBackgroundColor: #f3f3f3;
  --formFieldColor: var(--secondary-color);
  --formFieldBorderRadius: 8px;
  --formFieldFontSize: 16px;
  --formFieldLetterSpacing: .2px;
}

//mk-form-builder
html {
  --formBuilderErrorBackground: #FFF3F3;
  --formBuilderErrorBorder: #E23535;
  --formBuilderErrorColor: #E23535;
}

//mkAlertCenter
html {
  --alertFontSize: 14px;
  --alertBorderRadius: 10px;
  --alertPadding: 10px 14px;

  //types
  --alertSuccessBackground: rgba(245, 255, 250, .95);
  --alertSuccessColor: #147447;
  --alertSuccessBorder: #caf3da;

  --alertWarningBackground: rgba(255, 251, 245, .95);
  --alertWarningColor: #a45a11;
  --alertWarningBorder: #f3dfca;

  --alertErrorBackground: rgba(255, 243, 243,.95);
  --alertErrorColor: #E23535;
  --alertErrorBorder: #eccccc;
}
